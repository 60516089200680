import { useContext, useState, useEffect } from 'react';
import { ThemeContext } from './contexts/theme';
import Header from './components/Header/Header';
import About from './components/About/About';
import Projects from './components/Projects/Projects';
import Skills from './components/Skills/Skills';
import Education from './components/Education/Education';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';
import Loader from './components/Loader/Loader';
import './App.css';

const App = () => {
  const [{ themeName }] = useContext(ThemeContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 4000); // 4 seconds

    return () => clearTimeout(timer);
  }, []);

  return (
    <div id='top' className={`${themeName} app`}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Header />
          <main>
            <About />
            <Projects />
            <Skills />
            <Education />
            <Contact />
          </main>
          <ScrollToTop />
          <Footer />
        </>
      )}
    </div>
  );
};

export default App;
