import React from 'react'
import './Education.css'
import { education, courses } from '../../portfolio'

const Education = () => (
  <section id='education' className='section education'>
    <h2 className='section__title'>Education</h2>
    
    <div className='education__list'>
      {education.map((edu, index) => (
        <div key={index} className='education__item'>
          <h3>{edu.institution}</h3>
          <p>{edu.degree}</p>
          <p>{edu.duration}</p>
          <ul>
            {edu.details.map((detail, i) => (
              <li key={i}>{detail}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
    
    <h2 className='section__title'>Courses & Certifications</h2>
    <div className='courses__list'>
      {courses.map((course, index) => (
        <div key={index} className='course__card'>
          <h3>{course.title}</h3>
          <p>{course.provider}</p>
          <p>{course.date}</p>
        </div>
      ))}
    </div>
  </section>
)

export default Education
