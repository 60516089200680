const header = {
  // all the properties are optional - can be left empty or deleted
  homepage: 'https://takudzwa.tech',
  title: 'TN.',
}

const about = {
  // all the properties are optional - can be left empty or deleted
  name: 'Takudzwa Nhawu',
  role: 'Front End Engineer',
  description:
"I am a Computer Science graduate from the University of Zimbabwe (UZ) with a passion for technology. Skilled in software development and cybersecurity, I thrive on solving complex problems and delivering quality solutions. As a motivated self-starter, I continuously learn and adapt to new tech trends. I am eager to contribute my expertise and enthusiasm to innovative and dynamic teams.",

resume: 'https://example.com',
  social: {
    linkedin: 'https://linkedin.com',
    github: 'https://github.com',
  },
}

const education = [
  {
    institution: 'University of Zimbabwe',
    degree: 'Bachelor of Science in Computer Science',
    duration: '2020 - ',
    details: [
      '',
      '',
    ],
  },
  // Add more education entries as needed
]

const courses = [
  {
    title: 'Full Stack Web Development',
    provider: 'DevTown.in',
    date: '2023',
  },
  {
    title: 'React Frontend development',
    provider: 'Coursera',
    date: 'In Progress',
  },
  {
    title: 'Backend dev with Springboot',
    provider: 'Scaler',
    date: '2024',
  },
  {
    title: 'ISC2 Cyber Security Specialist (CC)',
    provider: 'ISC2',
    date: 'In Progress',
  },
  
  // Add more course entries as needed
]





const projects = [
  // projects can be added and removed
  // if there are no projects, Projects section won't show up
  {
    name: 'Smart Farm Management System',
    description: 'Developed a system using HTML, JavaScript, and PHP to visualize current and expected crop yields, manage farm fields, and store crop information. Designed for use by anyone interested in agriculture.',
    stack: ['HTML', 'JavaScript', 'PHP'],
    sourceCode: 'https://github.com/username/smart-farm-management-system', // Replace with actual URL
    livePreview: 'https://live-preview-url.com/smart-farm-management-system', // Replace with actual URL
  },
  {
    name: 'Machine Learning Model to Predict Farm Crop Yield',
    description: 'Created a machine learning model to predict farm crop yields based on historical data and environmental factors. Integrated into the Smart Farm Management System for accurate crop production insights.',
    stack: ['Python', 'scikit-learn', 'Pandas'],
    sourceCode: 'https://github.com/username/crop-yield-prediction-model', // Replace with actual URL
    livePreview: 'https://live-preview-url.com/crop-yield-prediction-model', // Replace with actual URL
  },
  {
    name: 'IoT Sensor Network using Raspberry Pi and ThingSpeak',
    description: 'Implemented an IoT sensor network to collect and analyze real-time environmental data. Utilized Raspberry Pi for data collection and ThingSpeak for cloud-based visualization and analysis.',
    stack: ['Raspberry Pi', 'Python', 'ThingSpeak'],
    sourceCode: 'https://github.com/username/iot-sensor-network', // Replace with actual URL
    livePreview: 'https://live-preview-url.com/iot-sensor-network', // Replace with actual URL
  },
  {
    name: 'Portfolio Website',
    description: 'Developed a minimalist portfolio website showcasing professional skills, projects, and contact information. Features include smooth navigation and responsive design.',
    stack: ['ReactJS', 'CSS', 'JavaScript'],
    sourceCode: 'https://github.com/username/portfolio-website', // Replace with actual URL
    livePreview: 'https://live-preview-url.com/portfolio-website', // Replace with actual URL
  },
];

const skills = [
  // skills can be added or removed
  // if there are no skills, Skills section won't show up
  'HTML',
  'CSS',
  'JavaScript',
  'Python',
  'React',
  'Springboot',
  'Git',
  'Java',
]

const contact = {
  // email is optional - if left empty Contact section won't show up
  email: 'takudzwa@takudzwa.tech',
}

export { header, education, courses, about, projects, skills, contact }
